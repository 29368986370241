/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useLunr  } from 'react-lunr';
import lunrData from '../../static/search-index.json';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import GlobalSearchLanding from '../components/GlobalSearchLanding';

export default function Search({ location, pageContext, data }) {
  // const [query] = useState(new URLSearchParams(location.search).get('s') || '');
  const query = new URLSearchParams(location.search).get('s') || '';
  let results=[];
  let resultLoaded = false;
  if(query?.length > 0){
    results = useLunr(`+*${query?.toLowerCase()}*`, lunrData?.index, lunrData?.store).filter(item => {
      return (item.body).toLowerCase().includes(query?.toLowerCase())
    });
    resultLoaded = true;
  }
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];

  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };

  // useEffect(() => {
  //   const getUser = JSON.parse(localStorage.getItem('user'));
  //   setUser(getUser);
  // }, [user]);
  return (
    <Layout
      partnerCode={pageContext.partnerCode}
      navigation={pageContext.appData}
      footer={allFooterData}
      styles={pageContext.theme}
      contactFormDetails={pageContext.appData.contactUsFormDetails}
      siteType={pageContext.appData.siteType}
      logout={logoutUser}
      metadata={pageContext.theme.partnerMetaData}
      searchEnabled={pageContext.appData.enableSearch}
      astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
      basePath={pageContext.basePath}
      bucketName={pageContext?.bucketName}
      location={location}
    >
      <SEO
          data={data?.contentfulSearchLandingPage?.seoEntry}
          siteurl={pageContext?.siteurl}
          bucketName={pageContext?.bucketName}
          basePath={pageContext?.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
      <GlobalSearchLanding
        resultData={results}
        data={data?.contentfulSearchLandingPage}
        searchKey={query}
        basePath={pageContext.basePath}
        resultLoaded={resultLoaded}
        location={location}
      />
    </Layout>
  );
}

export const query = graphql`
  query SearchLanding($contentful_id: String, $locale: String) {
    contentfulSearchLandingPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $locale }
    ) {
      entryTitle
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
      header
      errorMessage
      loadMore
      placeHolder
      searchButton
      backButtonImage {
        description
        file {
          fileName
          url
        }
      }
      backButtonHoverImage{
        description
        file {
          url
        }
      }
      capsulesBackgroundImage {
        description
        file {
          fileName
          url
        }
      }
    }
  }
`;
