import React, { useRef, useState, useEffect } from 'react';
import { Grid, Button, TextField, FormControl } from '@mui/material';
import { navigate } from 'gatsby-link';
import CloseIcon from '@mui/icons-material/Close';
import './GlobalSearchBar.scss';
import { myStyles } from './styles';
import clsx from 'clsx';


export default function GlobalSearchBar({location, data, searchKey, basePath}) {
  const [searchText, setSearchText] = useState(new URLSearchParams(location?.search).get('s'));
  const [btnDisabled, setBtnDisabled] = useState(true); 
  const classes = myStyles(data);
  const buttonEl = useRef(null);
  const registerAriaLabel = ()=>{
    buttonEl?.current?.querySelectorAll(".MuiInputBase-input").forEach((role)=>{
      role.setAttribute('aria-label', 'input-field');  
    });
  }
  const handleGoBack = () => {
    setSearchText();
    buttonEl.current.value = '';
    if(location?.hash == "#main"){
      navigate(-2);
    }else{
    navigate(-1);
    }
  };
  const clearSearchText = () => {
    setSearchText('');
  };
  const goToSearch = () => {
    if (searchText.trim()) {
      let searchQuery=searchText.replace(/-/g,'–')
      navigate( `/${basePath}/search/?s=${searchQuery?.trim().replace(/\s+/g, '+')}`)
    }
  };
  const handleSearchOnClick = (e) => {
    goToSearch();
  };
  const handleSearchOnEnter = (e) => {
    if (e.charCode == 13) {
      goToSearch();
    }
  };
  const handleEnterKeyPressClear = (e) => {
    if (e.charCode == 13) {
      clearSearchText();
    }
  };
  const handleEnterKeyPressBack = (e) => {
    if (e.charCode == 13) {
      handleGoBack();
    }
  };
 useEffect(() => {
    searchText?setBtnDisabled(false):setBtnDisabled(true)
  }, [searchText]);
  useEffect(() => {
    const query = new URLSearchParams(location.search).get('s')
    if(!searchText || searchText!=query){
      setSearchText(query)
    }
    registerAriaLabel();
  }, [location]);
  return (
    <>
      <Grid container className="globalSearchForm">
        <Grid item xs={12}>
          <div
            onClick={handleGoBack}
            locId="backButtonImage"
            tabIndex="0"
            onKeyPress={(e) => {
              handleEnterKeyPressBack(e);
            }}
            className={clsx('ArrowCircleLeft-search', classes['backButtonImage'])}
          ></div>
        </Grid>
        <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xl={8} lg={8} md={8} sm={8} xs={7}>
              <FormControl fullWidth>
                <TextField
                  ref={buttonEl}
                  className="form-input form-input-icon"
                  value={searchText}
                  placeholder={data?.placeHolder}
                  onKeyPress={(e) => handleSearchOnEnter(e)}
                  onChange={(e) => setSearchText(e.target.value)}
                  locId="searchText"
                />
                {searchText?.length > 0 ? (
                  <div className="search-cross-icon">
                    <CloseIcon
                      onClick={clearSearchText}
                      onKeyPress={(e) => {
                        handleEnterKeyPressClear(e);
                      }}
                      tabIndex="0"
                      locId="closeIcon"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={5}>
              <Button
                className="search-btn"
                onClick={(e) => handleSearchOnClick(e)}
                disabled={btnDisabled}
                tabIndex="0"
                locId="searchButton"
              >
                {data?.searchButton}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
          &nbsp;
        </Grid>
      </Grid>
    </>
  );
}