import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
    'backButtonImage': (data) => ({
        background: `url(${data?.backButtonImage?.file?.url}?fm=webp&q=100) no-repeat`,
        '&:hover': {
            background: `url(${data?.backButtonHoverImage?.file.url}?fm=webp&q=100) no-repeat`,
          },
    }),
}));
