import React from 'react';
import './GlobalSearchLanding.scss';
import GlobalSearchHeader from './GlobalSearchHeader';
import { myStyles } from './styles';
import clsx from 'clsx';

const GlobalSearchLanding = ({resultData, data, searchKey, basePath, resultLoaded, location}) => {
  const classes = myStyles(data?.capsulesBackgroundImage);
  return (
    <div className="globalsearch-landing-main" locId="globalSearchLanding">
      <div className={clsx('bg-capsules-globalsearch-landing', classes['bg-img-capsule-right'])}>
        <GlobalSearchHeader resultData={resultData} data={data} searchKey={searchKey} basePath={basePath} resultLoaded={resultLoaded} location={location}/>
      </div>
    </div>
  );
};
export default GlobalSearchLanding;
