import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './GlobalSearchTiles.scss';
import LoadMoreButton from '../../LoadMoreButton';
import { Link } from 'gatsby';

const GlobalSearchTiles = ({ resultData, basePath, searchKey, buttonLabel }) => {
  const numberOfTilePerPage = parseInt(process.env.GATSBY_NUMBER_OF_TILES_IN_SEARCH_LANDING);
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const [colsCount, setColsCount] = useState(numberOfTilePerPage);

  const globalSearchList = resultData.map((global) => {
    const months = ['JAN','FEB', 'MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    const dateinTimestamp = new Date(global?.updatedAt).getTime();
    const month = new Date(Number(dateinTimestamp)).getMonth();
    const monthString = months[month];
    const day = new Date(Number(dateinTimestamp)).getDate();
    const formatedDateString =
      monthString + ' ' + day + ', ' + new Date(Number(dateinTimestamp)).getFullYear();
    return {
      ...global,
      publishingDate: formatedDateString,
      dateinTimestamp: dateinTimestamp,
    };
  });

  function comparePublishingDate(a, b) {
    return b.dateinTimestamp - a.dateinTimestamp;
  }

  globalSearchList.sort(comparePublishingDate);

  const tilesAddMore = () => {
    let nextColsCount =
      resultData.length > colsCount + numberOfTilePerPage
        ? colsCount + numberOfTilePerPage
        : resultData.length;
        if (globalSearchList.length == nextColsCount) {
          setDisableLoadMore(true);
        }
    setColsCount(nextColsCount);
  };
  function trimParaText(text){
    if(text.length>200){
      const startStringIndex = text.lastIndexOf(" ",text.toLowerCase().indexOf(searchKey.toLowerCase()));
      const trimmedText = (text.toLowerCase().indexOf(searchKey.toLowerCase())>100 && (text.length - startStringIndex)>190)?  text.substring(startStringIndex+1,text.indexOf(" ",startStringIndex+190)) : text.substring(startStringIndex-190,text.indexOf(" ",startStringIndex + searchKey.length)) 
      return text.toLowerCase().indexOf(searchKey.toLowerCase())>100 ? `...${trimmedText}...`:`${text.substring(0,text.indexOf(" ",190))}...`
    }
      return text;
  }

  return (
    <>
      <Grid container xl={12} lg={12} md={12} sm={12} xs={12}>
        {globalSearchList.slice(0, colsCount).map((item, index) => {
          return (
            <>
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card variant="outlined" className="heading-blogs">
                  <Link to={`/${basePath}/` + item.slug} tabindex="0" className="heading-blogs-link">
                    <CardContent>
                      <div className="my-header">
                        <h2 className="globalsearch-title" locId="title">{item?.title}</h2>
                        {item?.category && <p className="tile-subhead" locId="category">[{item?.category}]</p>}
                      </div>
                      <p className="globalsearch-desp" locId="body">{`${trimParaText(item?.body)}`}</p>
                    </CardContent>
                  </Link>
                </Card>
              </Grid>
            </>
          );
        })}
      </Grid>
      {(!disableLoadMore && numberOfTilePerPage<resultData.length) ? (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <LoadMoreButton
            loadMoreButton={buttonLabel}
            handleLoadMore={tilesAddMore}
            tabindex="0"
            locId="loadMoreButton"
          />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
export default GlobalSearchTiles;
