import React from 'react';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import './GlobalSearchHeader.scss';
import GlobalSearchTiles from '../GlobalSearchTiles';
import GlobalSearchBar from '../GlobalSearchBar';

const GlobalSearchHeader = ({resultData, data, searchKey, basePath, resultLoaded, location }) => {
  const searchResultCount = resultData.length;
  return (
    <>
      <Container maxWidth="xl" className="search-content-container">
        <Grid container className="global-search-cls">
          <GlobalSearchBar
            data={data}
            searchKey={searchKey}
            basePath={basePath}
            location={location}
          />
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div>
                <h1 className="globalsearch-result-title" locId="header">{data?.header}</h1>
                <p className="globalsearch-result-desp" locId="searchResultCount">
                  {resultLoaded && (searchResultCount?`${searchResultCount} results for ${searchKey}`:`${data?.errorMessage}`)}
                  
                </p>
              </div>
            </Grid>
          </Grid>
          {resultLoaded && <GlobalSearchTiles resultData={resultData} searchKey={searchKey} basePath={basePath} buttonLabel={data?.loadMore}/>}
        </Grid>
      </Container>
    </>
  );
};

export default GlobalSearchHeader;
